import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './index.css';
import App from './App';  // Ensure App is imported correctly
import registerServiceWorker from './registerServiceWorker';
import config from './config.json';
// import config from './aws-exports';
import AWS from 'aws-sdk';
import { withAuthenticator, Authenticator } from 'aws-amplify-react';  // Import Authenticator

AWS.config.update({region: 'ap-southeast-2'});

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    identityPoolId: config.identityPoolId,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  },
});

// Wrap your root component with the withAuthenticator HOC
const AuthenticatedApp = withAuthenticator(App);

// Render your root component inside the root div
ReactDOM.render(<AuthenticatedApp />, document.getElementById('root'));
registerServiceWorker();

import { AmplifyTheme } from 'aws-amplify-react';

const CloudMatesTheme = {
  ...AmplifyTheme,
  container: {
    ...AmplifyTheme.container,
    backgroundColor: "#332CA5",
  },
  // You can add other custom styles or override existing ones
};

export default CloudMatesTheme;
